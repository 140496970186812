<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span>{{title}}</span>
    <span class="example-spacer"></span>
    <mat-icon class="example-icon" aria-hidden="false" aria-label="Example user verified icon" (click)="openDialog()">help</mat-icon>
  </mat-toolbar-row>
</mat-toolbar>

<router-outlet>
  <main>
    <app-calculate-tip></app-calculate-tip>
  </main>
</router-outlet>