<mat-grid-list cols="1" gutterSize="5px" rowHeight="500px">

    <mat-grid-tile>
        <table style="width: 100%">
            <tr>
                <td class="align-center" colspan="2">
                    <h1>
                        <mat-icon aria-hidden="false">restaurant_menu</mat-icon>
                        Calculate Tip
                        <mat-icon aria-hidden="false">restaurant_menu</mat-icon>
                    </h1>
                </td>
            </tr>
            <tr>
                <td class="align-center" colspan="2">
                    <mat-form-field>
                        <input matInput type="number" width="100%" [(ngModel)]="bill.amount" (blur)="calculateTip()"
                            placeholder="Bill Amount" [style.fontSize.px]="20" />
                        <span matPrefix>$&nbsp;</span>
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <td class="align-center" colspan="2">
                    <table>
                        <tr>
                            <td class="align-center"> <button mat-icon-button style="font-size:60px;color:#ffdb58;"
                                    class="large" (click)="toggleStar1()">
                                    <mat-icon>{{star1 ? 'star' : 'star_border'}}</mat-icon>
                                </button></td>
                            <td class="align-center">
                                <button mat-icon-button style="font-size:60px;color:#ffdb58;" class="large"
                                    (click)="toggleStar2()">
                                    <mat-icon>{{star2 ? 'star' : 'star_border'}}</mat-icon>
                                </button>
                            </td>
                            <td class="align-center"> <button mat-icon-button style="font-size:60px;color:#ffdb58;"
                                    class="large" (click)="toggleStar3()">
                                    <mat-icon>{{star3 ? 'star' : 'star_border'}}</mat-icon>
                                </button></td>
                            <td class="align-center"> <button mat-icon-button style="font-size:60px;color:#ffdb58;"
                                    class="large" (click)="toggleStar4()">
                                    <mat-icon>{{star4 ? 'star' : 'star_border'}}</mat-icon>
                                </button></td>
                            <td class="align-center"> <button mat-icon-button style="font-size:60px;color:#ffdb58;"
                                    class="large" (click)="toggleStar5()">
                                    <mat-icon>{{star5 ? 'star' : 'star_border'}}</mat-icon>
                                </button></td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td colspan="2" class="align-left">
                    <h4>Bill Details:</h4>
                </td>
            </tr>
            <tr>
                <td class="align-right">
                    Tip ({{bill.tipPercent}}%):
                </td>
                <td class="align-left">
                    {{bill.tipAmount | currency}}
                </td>
            </tr>
            <tr>
                <td class="align-right">
                    TOTAL:
                </td>
                <td class="align-left">
                    {{bill.totalAmount | currency}}
                </td>
            </tr>
            <tr>
                <td colspan="2" class="align-left">
                    <h4>Bill Details (TOTAL Amount rounded):</h4>
                </td>
            </tr>
            <tr>
                <td class="align-right">
                    Tip (~{{bill.tipPercentRounded}}%):
                </td>
                <td class="align-left">
                    {{bill.tipAmountRounded | currency}}
                </td>
            </tr>
            <tr>
                <td class="align-right">
                    TOTAL:
                </td>
                <td class="align-left">
                    {{bill.totalAmountRounded | currency}}
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <hr />
                </td>
            </tr>
            <tr>
                <td class="align-right">
                    <button mat-raised-button color="primary" (click)="calculateTip()">
                        Calculate
                    </button>
                </td>
                <td class="align-left">
                    <button mat-raised-button (click)="initializeBill()">
                        Clear
                    </button>
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <hr />
                </td>
            </tr>
        </table>
    </mat-grid-tile>
</mat-grid-list>