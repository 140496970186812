import { Component, OnInit } from '@angular/core';
import { Bill } from 'src/app/models/bill';

@Component({
  selector: 'app-calculate-tip',
  templateUrl: './calculate-tip.component.html',
  styleUrls: ['./calculate-tip.component.scss']
})
export class CalculateTipComponent implements OnInit {
  star1 = true;
  star2 = true;
  star3 = true;
  star4 = false;
  star5 = false;
  selected = 'star3';
  bill: Bill;

  ngOnInit(): void {
    this.initializeBill();
  }

  /**
   * initialize bill details
   */
  public initializeBill(): void {
    this.bill = {
      amount: null,
      rounded: true,
      tipAmount: 0,
      tipPercent: 15,
      totalAmount: 0,
      totalAmountRounded: 0,
      tipAmountRounded: 0,
      tipPercentRounded: 15
    };

    // set star 3 as selected
    this.selected = 'star3';
    this.star1 = true;
    this.star2 = true;
    this.star3 = true;
    this.star4 = false;
    this.star5 = false;
    console.log(`Bill Initialized: ${JSON.stringify(this.bill)}`);
  }

  /**
   * calculate tips value.
   */
  public calculateTip(): void {
    try {
      if (this.bill.amount && !isNaN(this.bill.amount)) {

        switch (this.selected) {
          case 'star1':
            this.bill.tipPercent = 10;
            break;
          case 'star2':
            this.bill.tipPercent = 12;
            break;
          case 'star3':
            this.bill.tipPercent = 15;
            break;
          case 'star4':
            this.bill.tipPercent = 18;
            break;
          case 'star5':
            this.bill.tipPercent = 20;
            break;

          default:
            break;
        }
        this.bill.tipAmount = +this.bill.amount * this.bill.tipPercent / 100;
        this.bill.totalAmount = +this.bill.amount + this.bill.tipAmount;
        if (this.bill.rounded) {
          this.bill.totalAmountRounded = Math.ceil(this.bill.totalAmount);
          this.bill.tipAmountRounded = this.bill.totalAmountRounded - this.bill.amount;
          this.bill.tipPercentRounded = Math.ceil(this.bill.tipAmountRounded * 100 / this.bill.amount);
        }
        console.log(`Bill Details: ${JSON.stringify(this.bill)}`);
      } else {
        // reset bill details.
        this.initializeBill();
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * toggle star1
   */
  public toggleStar1(): void {
    if (this.star1) {
      this.selected = 'star1';
      this.star2 = false;
      this.star3 = false;
      this.star4 = false;
      this.star5 = false;
    }

    this.calculateTip();
  }

  /**
   * toggle star2
   */
  public toggleStar2(): void {
    this.star2 = true;
    if (this.star2) {
      this.selected = 'star2';
      this.star1 = true;
      this.star2 = true;
      this.star3 = false;
      this.star4 = false;
      this.star5 = false;
    }
    this.calculateTip();
  }

  /**
   * toggle star3
   */
  public toggleStar3(): void {
    this.star3 = true;
    if (this.star3) {
      this.selected = 'star3';
      this.star1 = true;
      this.star2 = true;
      this.star3 = true;
      this.star4 = false;
      this.star5 = false;
    }
    this.calculateTip();
  }

  /**
   * toggle star4
   */
  public toggleStar4(): void {
    this.star4 = true;
    if (this.star4) {
      this.selected = 'star4';
      this.star1 = true;
      this.star2 = true;
      this.star3 = true;
      this.star4 = true;
      this.star5 = false;
    }
    this.calculateTip();
  }

  /**
   * toggle star5
   */
  public toggleStar5(): void {
    this.star5 = true;
    if (this.star5) {
      this.selected = 'star5';
      this.star1 = true;
      this.star2 = true;
      this.star3 = true;
      this.star4 = true;
      this.star5 = true;
    }
    this.calculateTip();
  }
}
